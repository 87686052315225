/**
 * Loader for loading Swiper API dynamically.
 * Read more about the Swiper API at @link https://swiperjs.com/api/
 */

'use strict';

let module = undefined;

export class SwiperLoader {
  constructor() {
    return this
  }
  async load() {
    if ( module == undefined ) {
      module = await import('swiper/js/swiper.esm')
    }
    return module;
  }
}
