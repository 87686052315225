'use strict'

import 'regenerator-runtime/runtime.js'

import { Modal } from 'bootstrap';

import '../scss/main.scss'

window.Modal = Modal;

const loadSliders = () => {
  if (document.querySelector('.banner-slider')) {
    import('./components/banner-slider').then(({ BannerSlider }) => {
      document.querySelectorAll('.banner-slider').forEach((bannerSlider) => {
        new BannerSlider(bannerSlider)
      });
    });
  }
  if (document.querySelector('.listing-box-detailed-slider')) {
    import('./components/listing-box-detailed-slider').then(({ ListingBoxDetailedSlider }) => {
      document.querySelectorAll('.listing-box-detailed-slider').forEach((listingBoxDetailedSlider) => {
        new ListingBoxDetailedSlider(listingBoxDetailedSlider)
      });
    });
  }
  if (document.querySelector('.listing-box-slider')) {
    import('./components/listing-box-slider').then(({ ListingBoxSlider }) => {
      document.querySelectorAll('.listing-box-slider').forEach((listingBoxSlider) => {
        new ListingBoxSlider(listingBoxSlider)
      });
    });
  }
  if (document.querySelector('.detail-banner-slider')) {
    import('./components/detail-banner-slider').then(({ DetailBannerSlider }) => {
      document.querySelectorAll('.detail-banner-slider').forEach((detailBannerSlider) => {
        new DetailBannerSlider(detailBannerSlider)
      });
    });
  }
  if (document.querySelector('.detail-gallery-slider')) {
    import('./components/detail-gallery-slider').then(({ DetailGallerySlider }) => {
      document.querySelectorAll('.detail-gallery-slider').forEach((detailGallerySlider) => {
        new DetailGallerySlider(detailGallerySlider)
      });
    });
  }
};

const loadIcons = () => {
  import('./fontawesome-loader').then(({ fontawesomeLoader }) => {
    fontawesomeLoader()
  });
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    loadIcons()
    loadSliders()
  });
} else {
  loadIcons()
  loadSliders()
}
